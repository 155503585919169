import dayjs from "dayjs";
import { CurveType } from "recharts/types/shape/Curve";
import { SCALEOPS_COLORS } from "../../colors";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../utils/formatterUtils";

/**
 * Constants
 */

export const WASTE_KEY = "Waste";
export const WASTE_COLOR = SCALEOPS_COLORS.main.waste;
export const RED_STRIPES_PATTERN_UNIQUE_ID = "comp-chart-red-stripes-pattern";
export const INFO_ICON_SIZE = 14;
export const MAIN_WRAPPER_CLASS_NAME =
  "bg-white border border-border rounded unselectable-svg-text w-full flex flex-col justify-center items-center pr-8";

/**
 * Types
 */
export type ChartDataPoint = {
  timestamp: string | number;
  values: Record<string, number>;
};

export type ChartData = ChartDataPoint[];

export type ChartElement = {
  key: string;
  color: string; // stroke
  name?: string;
  type?: CurveType;
  strokeWidth?: string | number;
  fill?: string;
  fillOpacity?: number;
};

export enum YAxisTickFormatterType {
  Number = "number",
  Currency = "currency",
  Percentage = "percentage",
  Memory = "memory",
  CPU = "cpu",
}

/**
 * Utils
 * */

export const defaultXAxisTickFormatter = (value: number | string | undefined, viewPeriod: number) => {
  const epochValue = dayjs(String(value)).unix();
  return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriod);
};

export const renderNameFunction = (key: string, elements: ChartElement[]) => {
  const element = elements.find((e) => e.key === key);
  return element?.name ?? key;
};
