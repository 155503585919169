import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GetUnevictableGraph, GetUnevictableGraphResponse } from "../../api/fetcher";
import { SCALEOPS_COLORS } from "../../colors";
import { getEpochStartDate } from "../../utils/dateAndTimeUtils";
import ComposeChart from "../ComposeChart/ComposeChart";
import useGetComposeChartState from "../ComposeChart/useGetComposeChartState";
import { ChartData, ChartElement, renderNameFunction, WASTE_KEY, YAxisTickFormatterType } from "../ComposeChart/utils";
import CustomLegend from "../CustomLegend";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import { ChartsTabOptions } from "./Utils";

const OPTIMIZED_ALLOCATABLE_KEY = "OptimizedAllocatable";
const SHOW_WASTE = { fromKey: "allocatable", toKey: OPTIMIZED_ALLOCATABLE_KEY };
const syncId = "unevictable";

const { queryFn, queryKey } = GetUnevictableGraph();

const getInitialState = (daysAgo: ChartsTabOptions) => {
  const now = Date.now();
  const daysAgoInt = Number(daysAgo.replace(/\D/g, ""));
  const startDate = getEpochStartDate(daysAgoInt, now);
  return {
    from: startDate,
    to: now,
  };
};

const elements: ChartElement[] = [
  {
    key: OPTIMIZED_ALLOCATABLE_KEY,
    color: SCALEOPS_COLORS.main.green,
    name: "Optimized allocatable",
  },
  {
    key: "allocatable",
    color: SCALEOPS_COLORS.main.orange,
    name: "Allocatable",
  },
];

const UnEvictableChart = () => {
  const composeChartState = useGetComposeChartState({});
  const [cpuGraphData, setCpuGraphData] = useState<ChartData>([]);
  const [memoryGraphData, setMemoryGraphData] = useState<ChartData>([]);
  const [selectedTab, setSelectedTab] = useState(ChartsTabOptions.Week);
  const [dateRange, setRange] = useState(getInitialState(selectedTab));

  const { data, isLoading, error } = useQuery<GetUnevictableGraphResponse, Error>({
    queryKey: [queryKey, dateRange.from, dateRange.to],
    queryFn: () =>
      queryFn({
        from: dateRange.from,
        to: dateRange.to,
      }),
  });

  useEffect(() => {
    setRange(getInitialState(selectedTab));
  }, [selectedTab]);

  useEffect(() => {
    const cpu = [] as ChartData;
    const memory = [] as ChartData;
    if (data) {
      data.resources?.map((el) => {
        cpu.push({
          timestamp: String(el.timestamps),
          values: {
            ...(el?.cpu as { [key: string]: number }),
            [OPTIMIZED_ALLOCATABLE_KEY]:
              Number(el.cpu?.allocatable ?? 0) - Number(el.cpu?.blockedNodesAllocatable ?? 0),
          },
        });
        memory.push({
          timestamp: String(el.timestamps),
          values: {
            ...(el?.memory as { [key: string]: number }),
            [OPTIMIZED_ALLOCATABLE_KEY]:
              Number(el.memory?.allocatable ?? 0) - Number(el.memory?.blockedNodesAllocatable ?? 0),
          },
        });
      }) ?? [];
      console.log(cpu, memory);
      setCpuGraphData(cpu);
      setMemoryGraphData(memory);
    }
  }, [data, dateRange]);

  if (error) {
    console.log("Error fetching unevictable graphs", error);
    return null;
  }

  return (
    <div className="flex flex-col mt-[40px] h-[290px] relative bg-white w-full rounded-bl-lg border-br-lg border-tr-lg items-center justify-center">
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] w-full")}>
        {Object.entries(ChartsTabOptions).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={String(value)}
              dataTestId={`unevictable-chart-${key}-tab`}
            />
          );
        })}
      </div>
      <div className="flex w-full p-[20px] gap-4">
        <div className="w-[50%]">
          <ComposeChart
            data={cpuGraphData}
            title={"CPU"}
            isLoading={isLoading}
            elements={elements}
            yAxisTickFormatterType={YAxisTickFormatterType.CPU}
            syncId={syncId}
            tooltip={{
              tooltipId: "unevictable-tooltip-id",
            }}
            hasLegend={false}
            externalLegend={composeChartState}
            showWaste={SHOW_WASTE}
          />
        </div>
        <div className="w-[50%]">
          <ComposeChart
            data={memoryGraphData}
            title={"Memory"}
            isLoading={isLoading}
            elements={elements}
            yAxisTickFormatterType={YAxisTickFormatterType.Memory}
            syncId={syncId}
            tooltip={{
              tooltipId: "unevictable-tooltip-id",
            }}
            showWaste={SHOW_WASTE}
            hasLegend={false}
            externalLegend={composeChartState}
          />
        </div>
      </div>
      <div className="p-3">
        <CustomLegend<string>
          selectedChartComponents={composeChartState.selectedChartComponents}
          setSelectedChartComponents={composeChartState.setSelectedChartComponents}
          componentStyle={composeChartState.legendComponentStyle}
          ChartComponents={composeChartState.chartComponents}
          renderNameFunction={(key) => renderNameFunction(key, elements)}
          isDashedFnc={(key: string) => key.includes(WASTE_KEY)}
          className="-mt-1"
          fontWeight={500}
          fontSpanClassName="truncate"
          hasTooltip
        />
      </div>
    </div>
  );
};

export default UnEvictableChart;
